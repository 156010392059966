<!--
 * @Description: 
 * @version: 1.0
 * @Author: Sunwx
 * @Date: 2021-10-23 20:04:57
 * @LastEditors: Sunwx
 * @LastEditTime: 2021-10-26 09:19:14
-->
<template>
	<div id="main-page-div">
        <el-radio-group v-model="radioActive" size="mini" @change="radioClick">
            <el-radio-button label="日报"></el-radio-button>
            <el-radio-button label="周报"></el-radio-button>
            <el-radio-button label="季报"></el-radio-button>
            <el-radio-button label="年报"></el-radio-button>
            <!-- <el-radio-button label="单站统计"></el-radio-button> -->
        </el-radio-group>
        <div style="height: 20px;"></div>
        <report-day v-if="ifvar.day" :deviceData="deviceData" :devicesList="devicesList"></report-day>
        <report-weeks v-if="ifvar.weeks" :deviceData="deviceData" :devicesList="devicesList"></report-weeks>
        <report-season v-if="ifvar.season" :deviceData="deviceData" :devicesList="devicesList"></report-season>
        <report-year v-if="ifvar.year" :deviceData="deviceData" :devicesList="devicesList"></report-year>
        <!-- 站点统计 -->
        <!-- <report-single v-if="ifvar.single" :deviceData="deviceData"></report-single> -->
	</div>
</template>
<script>
    const MyEcharts= ()=> import ("@/views/second/ECharts")
    const reportDay= ()=> import ("@/views/second/components/reportDay")
    const reportWeeks= ()=> import ("@/views/second/components/reportWeeks")
    const reportSeason= ()=> import ("@/views/second/components/reportSeason")
    const reportYear= ()=> import ("@/views/second/components/reportYear")
    const reportSingle= ()=> import ("@/views/second/components/reportSingle")
	export default {
		data() {
			return {
                radioActive: '日报',
                ifvar: {
                    day: true,
                    weeks: false,
                    season: false,
                    year: false,
                    single: false,
                },
                // 设备信息
                deviceData:{},
                devicesList:[]
			};
		},
        components: {
            MyEcharts,
            reportDay,
            reportWeeks,
            reportSeason,
            reportYear,
            reportSingle,
        },
		created() {
		},
        mounted(){
            this.OnMsgFun()
        },
        watch: {
			"$store.getters.getCurDevices": {
				handler: function(newVal, oldVal) {
                    console.log('设备数据',newVal)
					// 设备数据
					if (newVal && newVal.length == 1) {
                       this.deviceData = Object.assign({},...newVal);
                    }else{
                        this.deviceData = {}
                    }
				}
			},
		},
		methods: {
            OnMsgFun(){
                 // 获取设备
                this.ws.addCallback('devicesAck', (res) => {
                    this.devicesList = res.devices 
                    })
            },
            // 获取设备
            /**
             * @description: 单选框切换
             * @param {*} active
             * @return {*}
             * @author: Sunwx
             */            
            radioClick(active){
                this.resetIfVar();
                switch(active){
                    case "单站统计":
                        this.ifvar.single = true;
                        break;
                    case "日报":
                        this.ifvar.day = true;
                        break;
                    case "周报":
                        this.ifvar.weeks = true;
                        break;
                    case "季报":
                        this.ifvar.season = true;
                        break;
                    case "年报":
                        this.ifvar.year = true;
                        break;
                }
            },
            /**
             * @description: 重置组件显示
             * @param {*}
             * @return {*}
             * @author: Sunwx
             */            
            resetIfVar(){
                this.ifvar = {
                    day: false,
                    weeks: false,
                    season: false,
                    year: false,
                    single: false,
                }
            }
		}
	};
</script>

<style lang="scss">
</style>
